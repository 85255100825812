/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Rizki Nida Chaerulsyah - akuiki.net
==============================================================================================*/

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:500,400,300,700);
body {
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
    clear: both;
    margin: 0;
    height: auto;
    box-sizing: border-box;
    overflow-x: hidden;
    color: #333;
    font-size: 12px;
    font-family: "Montserrat";
    overflow-x: hidden;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

*,
 :after,
 :before {
    box-sizing: border-box;
}

.main-header,
header,
.menu-content,
.all-content {
    max-width: 100%;
    line-height: inherit;
    margin-left: auto;
    margin-right: auto;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.regular {
    font-weight: lighter;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

p {
    margin: 1px 0 15px;
    line-height: 20px;
}

input,
textarea,
select {
    font-size: 12px;
    font-family: "Ubuntu";
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    -webkit-appearance: none;
}

input[type=checkbox],
input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}


/* structure
----------------------------------------------------------------------------------------------*/

.wrapper {
    width: 960px;
    margin: 0 auto;
}


/* global
----------------------------------------------------------------------------------------------*/

.after-clear:after {
    display: block;
    clear: both;
    content: '';
}

.before-clear:before {
    display: block;
    clear: both;
    content: '';
}

.after-none:after {
    display: none !important;
}

.align-left {
    text-align: left !important;
}

.align-center {
    text-align: center !important;
}

.align-right {
    text-align: right !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-border {
    border: none !important;
}

.list-box .box .label-ico img,
header .left.search input,
.list-box .box .text *,
.content .title-page .nav a,
.wrap-tab .nav-tab a,
.grid-view .box--grid .box--column.callus:hover .text img,
.inner-content .timeline .box,
.transition {
    transition: 0.2s all linear;
    -webkit-transition: .2s all linear;
    -moz-transition: .2s all linear;
    -ms-transition: .2s all linear;
    -o-transition: .2s all linear;
}

.image {
    position: relative;
    overflow: hidden;
}

.image.image-full img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image.image-cover img {
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.all-content {
    position: relative;
    padding: 60px 0 0;
}

.data-popup {
    display: none;
}

form p.note {
    font-size: 12px;
    font-style: italic;
    line-height: 14px;
    margin: 5px 0 0 0;
    ;
}

.inner-content .form_contact .row .div_check span {
    position: relative;
    padding: 0 0 0 30px;
    min-height: 21px
}

.inner-content .form_contact .row .div_check .check-custom {
    position: absolute !important;
    top: 0;
    left: 0
}

.inner-content .qoutes {
    font-size: 36px;
    color: #999999;
    font-style: italic;
    position: relative;
    padding-left: 30px;
    margin-bottom: 35px;
    display: block;
    line-height: 40px;
}

.inner-content .qoutes:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 85px;
    background: #e2211a;
}


/* header & nav
----------------------------------------------------------------------------------------------*/

header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 61px;
    background: #ffff;
    z-index: 3;
}

header .header-container {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 100%;
    padding: 0 20px 0 95px;
}

header .logo {
    margin: 15px 0 0;
    width: 230px;
}

header .left {
    margin: 15px 50px 0 0;
    height: 37px;
    position: relative;
}

header .left:after {
    border-right: 1px solid #d8d8d8;
    height: 37px;
    content: "";
    display: block;
    right: -25px;
    top: 0;
    position: absolute
}

header .left a {
    line-height: 37px;
    padding: 0 0 0 30px;
    font-size: 16px;
    color: #666;
}

header .left a:hover {
    color: #0076bd
}

header .left a.phone {
    background: url(../images/material/phone-ico.png) no-repeat left center;
    padding-left: 40px;
}

header .left a.customer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: end;
    padding-left: 0px;
}

header .left a.customer svg {
    width: 33px;
    height: 33px;
}

header .left.lang {
    position: relative;
    background: url(../images/material/lang-ico.png)no-repeat left;
    padding: 0 0 0 25px;
    margin-right: 40px;
}

header .left.lang:after {
    content: "";
    display: block;
    position: absolute;
    right: -15px;
    top: 17px;
    height: 0;
    width: 0;
    border-top: 3px solid #0076bd;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

header .left.lang a,
header .left.search input {
    font-size: 16px;
    padding: 0;
    color: #999;
}

.lang .drop {
    display: none;
    position: absolute;
    background: #212121;
    width: 100px;
    top: 35px;
    left: 0;
    z-index: 4;
    padding: 5px 15px
}

header .left.lang .drop a,
.lang .drop a {
    display: block;
    border-bottom: 1px solid #333;
    margin: 10px 0 0px 0;
    padding: 0 0 10px 0;
    line-height: 14px;
}

header .left.lang .drop a:last-child,
.lang .drop a:last-child {
    border-bottom: none;
}

.lang .drop a:hover {
    color: #FFF;
}

header .left.search input {
    border-color: transparent;
    width: 115px;
    height: 37px;
    height: 14px;
    height: 20px;
    margin: 8px 0 0;
}

header .left.search input:focus {
    width: 200px;
    border-bottom-color: #999;
}

header .left.search:after {
    display: none;
}

.floatingmenu {
    position: fixed;
    width: 84.7%;
    height: 130px;
    z-index: 3;
    left: 30px;
    margin: auto;
    bottom: 0;
    background: rgba(7, 101, 157, 0.95);
}

.floatingmenu:after {
    content: '';
    display: block;
    clear: both;
}

.floatingmenu .form {
    z-index: 6;
    margin-top: 10px;
    padding-top: 10px;
}

.floatingmenu .form:after {
    content: '';
    display: block;
    clear: both;
}

.floatingmenu .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.floatingmenu .row:after {
    content: '';
    display: block;
    clear: both;
}

.sidebar-right {
    position: fixed;
    width: auto;
    height: auto;
    z-index: 5;
    right: 0px;
    bottom: 2vh;
}

.chat,
.solution {
    min-height: 90px;
    background-color: #0E7DC2;
    border-radius: 20px 0px 0px 20px;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
}

.chat {
    position: relative;
    right: -15px;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
}

.solution {
    position: relative;
    right: -5px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

.sidebar-right .sidebar-right--wrapper {
    position: relative;
    width: auto;
    height: auto;
    box-sizing: border-box;
}

.chat-wrapper {
    padding: 10px 15px;
}

.chat-wrapper .text {
    color: #ffff;
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

.solution .solution-left {
    padding-left: 5px;
    padding-right: 5px;
}

.solution.active .solution-left svg {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}

.solution .solution-right {
    padding-left: 15px;
    padding-right: 15px;
}

.solution .solution-right a {
    color: #ffff;
    font-size: 14px;
    line-height: 1.4em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.solution .solution-right a small {
    display: block;
    font-size: 10px;
}

.solution .solution--vertical-line {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 25px;
    width: 0.5px;
    height: 100%;
    background-color: #ffff;
}

.floatingmenu .row .col {
    width: 19.78%;
}

.floatingmenu .row .col.small {
    width: 6.278%;
}

.floatingmenu .row .col.sub {
    width: 12.222%;
}

.floatingmenu .row label {
    display: block;
    font-size: 15px;
    color: #fff;
    margin-bottom: 10px;
}

.floatingmenu input[type='text'],
select,
input[type='submit'] {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    font-size: 18px;
    z-index: 6;
    position: relative;
}

.floatingmenu input[type='submit'] {
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    background: #e2211a;
    text-transform: uppercase;
    border: none;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.floatingmenu input[type='submit']:hover {
    background: #ff3029;
}

.floatingmenu select {
    background: #fff url('../images/material/arr-selectfloat.png')no-repeat right;
}

.floatingmenu .virtualasist {
    margin: 15px 30px 0 0;
}

.main-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
}

aside {
    margin: 0px;
    z-index: 5;
    position: absolute;
    left: -20px;
    top: -30px;
}

aside:after {
    content: " ";
    position: absolute;
    right: -1px;
    top: 60px;
    height: 100%;
}

aside nav a {
    display: block;
    text-align: center;
    padding: 10px 10px 15px;
    background: #212121;
    font-size: 10px;
    text-transform: uppercase;
    color: #666;
    border-bottom: 1px solid #333;
    position: relative;
    word-break: break-word;
}

aside nav a {
    position: relative;
    padding: 0;
    font-size: 0;
}

aside nav a .ico {
    width: 40px;
    height: 60px;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    background-position: left center !important;
    background-size: auto 30px !important;
}

aside nav a .icon {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    flex-direction: column;
}

aside nav a.toggle-menu {
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: space-around;
    transform: scale(0.67);
    -webkit-transform: scale(0.67);
    -moz-transform: scale(0.67);
    -ms-transform: scale(0.67);
    -o-transform: scale(0.67);
}

aside nav a .box-hover .inner-hover {
    width: 60px;
    height: 60px;
    padding: 0;
}

aside nav a .ico {
    display: block;
    width: 70px;
    height: 50px;
    margin: 0 auto 2px auto
}

aside nav a:hover {
    color: #666;
}

aside nav a.toggle-menu {
    position: relative;
    background-color: #0076bd;
    transition: 0.2s background linear;
    -webkit-transition: 0.4s background linear;
    -moz-transition: 0.4s background linear;
    -ms-transition: 0.4s background linear;
}

aside nav a.toggle-menu:after {
    display: none;
    opacity: 0
}

aside nav a.toggle-menu .text {
    position: absolute;
    bottom: 10px;
    align-self: center;
    font-size: 16px;
    color: #ffff;
    text-transform: capitalize;
}

aside nav a.toggle-menu .text::after {
    content: 'Menu';
}

aside nav a.toggle-menu.active .text::after {
    content: 'Back';
}

aside nav a.toggle-menu span {
    display: block;
    background: #FFF;
    width: 50px;
    height: 6px;
    border-radius: 6px;
}

aside nav a.toggle-menu.active span:nth-child(1),
aside nav a.toggle-menu.active span:nth-child(3) {
    position: relative;
    width: 25px;
}

aside nav a.toggle-menu span:nth-child(1) {
    top: 5px;
    left: -15px;
}

aside nav a.toggle-menu span:nth-child(3) {
    top: -5px;
    left: -15px;
}

aside nav a.toggle-menu.active span:nth-child(1) {
    transform: matrix(0.76604, -0.64279, 0.64279, 0.76604, 0, 0);
}

aside nav a.toggle-menu.active span:nth-child(3) {
    transform: matrix(0.76604, 0.64279, -0.64279, 0.76604, 0, 0);
}

aside nav a.toggle-menu span:nth-child(2) {
    top: 47px;
}

aside nav a.toggle-menu:after {
    position: absolute;
    right: -1px;
    top: 0;
    border-right: 1px solid #0076bd;
    content: "";
    display: block;
    height: 100%;
    z-index: 2;
    display: none;
}

aside nav a.toggle-menu.active {
    background: #0076bd;
    border-bottom: none;
}

aside nav a.toggle-menu.active:after {
    display: block;
}

aside nav a .box-hover {
    position: absolute;
    right: -3px;
    top: 0;
    bottom: 0;
    display: block;
    padding: 0 5px 0 0;
    margin: auto;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 2;
}

aside nav a.active .box-hover {
    height: 100%;
}

aside nav a .box-hover .inner-hover {
    padding: 10px;
    width: 70px;
    height: 70px;
    display: block;
    color: #FFF;
    position: absolute;
    right: 3px;
    top: 0
}

aside nav a .box-hover .inner-hover .ico {
    background-position: right center !important;
}

aside nav a:nth-child(2) .box-hover {
    background: #e53730;
}

aside nav a:nth-child(3) .box-hover {
    background: #42b4e6;
}

aside nav a:nth-child(4) .box-hover {
    background: #b59331;
}

aside nav a:nth-child(5) .box-hover {
    background: #36c084;
}

aside nav a:nth-child(6) .box-hover {
    background: #9575cd;
}


/*aside nav a:nth-child(7) .box-hover{background:#ff8a65;}*/

aside nav a:nth-child(7) .box-hover {
    background: #444;
}

aside nav a:nth-child(8) .box-hover {
    background: #444;
}

.menu-content {
    display: none;
    position: absolute;
    z-index: 4;
    margin: 0 0px 0 0px;
    padding: 0 30px 0 0;
    top: 0;
    right: 100%;
    left: auto;
    min-height: 100%;
    background: #212121;
}

.menu-content .left,
.menu-content .right {
    position: relative;
}

.menu-content .left {
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 20px;
    width: 25%;
    background: #0076bd;
    min-height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.menu-content .left .box-menu {
    padding: 0 25px 0 0;
    color: #FFF;
}

.menu-content .left .search {
    margin: 80px 0 20px 0;
}

.menu-content .left .search input[type=text] {
    display: block;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    background-color: #ffff;
    padding: 0 50px 0 15px;
    height: 50px;
    color: #5F5F5F;
    width: 85%;
}

.menu-content .left .search input[type=text]::-webkit-input-placeholder {
    color: #a7a2a2;
    font-style: italic;
}

.menu-content .left .search input[type=text]:-moz-placeholder {
    color: #95d7ff;
    font-style: italic;
}

.menu-content .left .search input[type=text]::-moz-placeholder {
    color: #95d7ff;
    font-style: italic;
}

.menu-content .left .search input[type=text]:-ms-input-placeholder {
    color: #95d7ff;
    font-style: italic;
}

.menu-content .left .box-menu {
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #1d85c3;
}

.menu-content .left .box-menu h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 15px;
    text-transform: uppercase;
}

.menu-content .left .box-menu a {
    background: url(../images/material/arr-list-menu.png)no-repeat left center;
    display: block;
    font-size: 14px;
    color: #FFF;
    padding: 0 0 0 20px;
    margin: 0 0 10px 0;
}

.menu-content .left .box-menu a {
    padding: 0;
    background: none;
}

.menu-content .left .box-menu a:hover {
    color: #95d7ff
}


/*.menu-content .left .box-menu:nth-child(5){border:none;margin: 0}*/


/*.menu-content .left .box-menu:nth-child(5) h5{font-weight: 400;text-transform: capitalize;margin: 0 0 10px;}*/

.menu-content .left .lang {
    display: inline-block;
    position: relative;
    color: #ffff;
    background: url(../images/material/menu-lang-ico.png)no-repeat left;
    margin: 0 auto 38px 0px;
    padding: 0 0 0 25px;
    height: 17px;
    line-height: 17px;
}

.menu-content .left .lang:after {
    content: "";
    display: block;
    position: absolute;
    right: -15px;
    top: 8px;
    border-top: 3px solid #FFF;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
}

.menu-content .lang a {
    font-family: "Ubuntu";
    font-size: 14px;
    padding: 0;
    color: #FFF;
}

.menu-content .lang .drop a {
    margin: 10px 0 0 0;
    padding: 0 0 10px;
    color: #999;
}

.menu-content .lang .drop {
    top: auto;
    bottom: 25px
}

.menu-content .lang .drop a:hover {
    color: #FFF;
}

.menu-content .right {
    width: 75%;
    z-index: 1
}

.menu-content .right .wrapper {
    width: auto;
    margin: 140px 11.5% 0
}

.menu-content .logo {
    position: absolute;
    right: 30px;
    top: 25px;
}

.menu-content .right h5 {
    font-size: 24px;
    color: #666;
    text-transform: uppercase;
    margin: 0 0 20px
}

.menu-content .grid-view {
    margin: 0 0 75px 0;
}

.menu-content .grid-view .box {
    width: 33.33%;
}

.menu-content .grid-view .box:after {
    border-color: #212121;
}

.menu-content .grid-view .box .text h4 {
    font-size: 20px;
    margin-bottom: 10px;
}

.menu-content .grid-view .box .text p {
    font-size: 12px;
    line-height: 14px;
    margin: 0
}

.menu-content .right .box-news a {
    color: #5F5F5F;
    line-height: 50px;
    font-size: 48px;
}

.menu-content .right .box-news a span {
    display: block;
    margin: 10px 0 0 0;
    padding: 0 0 0 20px;
    background: url(../images/material/menu-date-ico.png) no-repeat left;
    font-family: "Ubuntu";
    font-size: 16px;
}

.menu-content .right .box-news a:hover {
    color: #212121;
}


/* middle
----------------------------------------------------------------------------------------------*/

section {
    position: relative;
}


/* detail
----------------------------------------------------------------------------------------------*/

.content.content-container .content-wrapper {
    padding-bottom: 5px;
}

.content-container {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-end;
}

.content-container .content-wrapper .inner-content {
    width: 100%;
}

.grid-view .box {
    width: 25%;
    height: auto;
    float: left;
    position: relative;
    overflow: hidden;
    color: #FFF;
    cursor: pointer
}

.inner-content .list-direksi .box .image:after,
.grid-view .box.slide-shadow:after,
.grid-view .box.slide-shadow .image:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    border-left: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.inner-content .list-direksi .box .image:after {
    content: "";
    background: url(../images/material/shadow-img-small.png) repeat-x bottom;
    border: none;
    background-size: auto 100%;
    height: 100%
}

.box--grid-wrapper .image:after {
    content: "";
    /* background: url(../images/material/shadow-img-small.png) repeat-x bottom; */
    border: none;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-size: auto 100%;
    height: 100%
}

.grid-view {
    width: 100%;
    overflow: hidden;
}

.grid-view .box .text {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 30px;
    color: #FFF;
    z-index: 1
}

.grid-view .left .box {
    width: 50%;
}

.grid-view .left,
.grid-view .slider-wrap {
    width: 100%;
}

.grid-view .box--grid {
    display: grid;
    width: 100%;
}

.box--grid.box--grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr))
}

.gap-1 {
    grid-gap: 1px;
}

.grid-view .box--grid .box--column.fund-linked a {
    bottom: 10px;
}

.grid-view .box--grid .box--column.testimonial a {
    position: absolute;
    bottom: 20px;
}

.grid-view .box--grid .box--column .box--grid-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.grid-view .box--grid .box--column .box--grid-wrapper.middle {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.grid-view .box--grid .box--column .box--grid-wrapper .image {
    height: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.grid-view .box--grid .box--column .box--grid-wrapper::after,
.grid-view .box--grid .box--column-wrapper .box--grid-wrapper::after {
    background: url(../images/material/shadow-img-big.png) repeat-x bottom;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text h6 {
    font-size: 14px;
    line-height: 16px;
}

.grid-view .box--grid .box--column .box--column-wrapper .text p {
    margin: 0px 0px 10px;
    line-height: 1.4em;
}

.grid-view .box--grid .box--column .box--column-wrapper .text h3 {
    font-weight: 600;
    margin-bottom: 10px;
}

.grid-view .box--grid .box--column .box--column-wrapper .text h6 {
    margin-bottom: 10px;
    line-height: 1.4em;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text a {
    font-size: 12px;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text a.text-center {
    margin: auto;
}

.grid-view .box--grid .box--column .box--grid-wrapper .icon {
    max-width: 90px;
    margin-bottom: 20px;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text.bottom {
    height: 100%;
    justify-content: flex-end;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text.center {
    text-align: center;
}

.grid-view .box--grid .box--column {
    text-align: center;
    display: flex;
    color: #ffff;
    align-self: stretch;
}

.grid-view .box--grid .box--column a {
    display: block;
    width: 100%;
    color: #0076bd;
}

.grid-view .box--grid .box--column a h4 {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
}

.grid-view .box--column .box---column-description {
    margin-bottom: 0px;
}

.grid-view .box.slider-wrap .bx-wrapper {
    z-index: 2;
}

.grid-view .box.slider-wrap .text {
    bottom: 150px;
}

.grid-view .box.slider-wrap .text h2 {
    font-family: "Ubuntu";
    font-size: 80px;
    line-height: 1.2em;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.grid-view .box.slider-wrap .text p {
    font-family: "Ubuntu";
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 35px
}

.grid-view .box.slider-wrap .bx-controls-direction {
    display: none;
}

.grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager {
    bottom: 22px;
}

.grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager a {
    background: none;
    height: 12px;
    width: 12px;
    border: 2px solid #FFF;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
}

.grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager a:hover,
.grid-view .box.slider-wrap .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #FFF;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text a,
.grid-view .box--grid .box--column-wrapper .box--grid-wrapper .text a,
.grid-view .slider .text .text-wrapper button {
    position: relative;
    width: auto;
    padding-right: 30px;
    max-width: max-content;
    display: flex;
    align-items: center;
    color: #ffff;
}

.grid-view .slider .text .text-wrapper button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 0px;
    font-size: 14px;
}

.grid-view .slider-img-text .slide .image-full::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
}

.grid-view .box--grid .box--column .box--grid-wrapper .text a::after,
.grid-view .box--grid .box--column-wrapper .box--grid-wrapper .text a::after,
.grid-view .slider .text .text-wrapper button::after {
    position: absolute;
    content: "";
    right: 0px;
    height: 16px;
    width: 16px;
    background: url(../images/material/arr-button.png) no-repeat right center;
}

.grid-view .box.slider-wrap .slide a:hover {
    text-decoration: underline
}

.grid-view .box .label-ico,
.grid-view .box--grid .label-ico {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
}

.grid-view .box .text h4 {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 3px;
    text-transform: uppercase;
}

.grid-view .box .text p {
    font-family: "Ubuntu";
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 15px;
}

.grid-view .box .text h6 {
    font-size: 18px;
    margin: 0 0 15px 0;
    line-height: 22px;
    color: #fff;
}

.grid-view .box--grid .text-money span,
.grid-view .box .text span {
    display: block;
    font-family: "Ubuntu";
    font-size: 11px;
    height: 13px;
}

.grid-view .box--grid .kurs {
    background-color: #0076BD;
    color: #ffff;
}

.grid-view .box .text span.date {
    line-height: 13px;
    padding: 0 0 0 20px;
    background: url(../images/material/date-ico.png) no-repeat left center;
}

.grid-view .box--grid .text-money {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    z-index: 1
}

.grid-view .box--grid .text-money span {
    font-size: 14px;
}

.grid-view .box--grid .text-money {
    text-align: center;
    padding: 30px;
}

.grid-view .box--grid .text-money h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0 5px 0;
}

.grid-view .box--grid .text-money h2 {
    font-size: 80px;
    margin: 20px 0px;
}

.grid-view .box--grid .box--column.fund .text-money h2 span {
    margin-top: 5px;
}

.grid-view .box--grid .text-money h2 span {
    font-size: 20px;
    height: 25px;
    margin: 0 0 10px;
    line-height: 25px;
    background: url(../images/material/arr-kurs.png) no-repeat center;
}

.grid-view .box--grid .box--column.fund .text-money {
    padding-top: 0;
}

.grid-view .box--grid .box--column.fund h5 {
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.4em;
}

.grid-view .box h5 {
    font-size: 24px;
    margin: 15% 0 5px 0;
}

.grid-view .box--grid .box--column.fund span {
    font-size: 14px;
}

.grid-view .box--grid .box--column.fund {
    text-align: center;
    position: relative;
}

.grid-view .box--grid .box--column.fund h2 span {
    background: none;
}

.grid-view .box--grid .box--column.fund .bx-controls-direction a {
    display: inline-block;
    z-index: 2;
    background: none;
    padding: 0;
    margin: 0
}

.grid-view .box--grid .box--column.fund .bx-wrapper {
    z-index: 2;
}

.grid-view .box--grid .box--column.fund .bx-viewport {
    background: none
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-pager {
    display: none;
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction {
    width: 100%;
    left: 0;
    height: 35px;
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a {
    position: absolute;
    width: 18px;
    height: 48px;
    background: url(../images/material/arr-carousel-fund.png) no-repeat;
    left: auto;
    right: auto;
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-prev {
    background-position: left center;
    left: 38%;
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-controls-direction a.bx-next {
    background-position: right center;
    right: 38%;
}

.grid-view .box .text.perencanaan h6 {
    font-size: 24px;
    color: #FFF;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    line-height: 22px;
}

.grid-view .box--grid .box--column .box--column-wrapper .box--grid-inner {
    height: 100%;
}

.grid-view .box--grid .box--column .box--column-wrapper .box--grid-inner .text a {
    font-size: 12px;
}

.grid-view .box--grid-inner .text.center a {
    margin-left: auto;
    margin-right: auto;
}

.grid-view .box--grid .box--column .box--column-wrapper .box--grid-inner .text .text-wrapper {
    width: 100%;
}

.grid-view .box--grid .box--column.customer {
    background: #2790cf;
}

.grid-view .box--grid .box--column.customer .text a.btn:hover {
    background: #FFF;
    color: #2790cf;
}

.grid-view .box--grid .box--column.customer .text img {
    margin: 0 0 20px 0;
    width: 33px;
}

.grid-view .box--grid .box--column.customer .text h2 {
    font-size: 50px;
    line-height: 1.4em;
    text-transform: uppercase;
    margin: 0 0 15px;
}

.grid-view .box--grid .box--column.customer .text a,
.grid-view .box--grid .box--column.customer .text span {
    font-size: 12px;
}

.grid-view .box--grid .box--column.customer .text a {
    background: none;
    padding: 0;
    text-decoration: underline;
}

.grid-view .box--grid .box--column.customer .text a:hover {
    color: #f05921
}

.grid-view .box--grid .box--column.customer .text a.btn {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-family: "Ubuntu";
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px 20px;
    box-sizing: content-box;
    border: 2px solid #FFF;
    text-decoration: none;
    margin: 20px 0 15px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.grid-view .box--grid .box--column.customer .text a.btn:hover {
    background-color: transparent !important;
}


/*.grid-view .box--grid .box--column.customer .text a.btn:hover{background: #FFF;color:#4527a0}*/

.grid-view .box--column.news {
    background: #f05921;
    position: relative;
    overflow: hidden;
}

.grid-view .box--column.news .text {
    bottom: 20%;
}

.grid-view .box--column.news .text h3 {
    font-size: 38px;
    margin: 0 0 15px;
}

.grid-view .box--column.news .bg-hover {
    background: #212121
}

.grid-view .box--grid .box--column.nasabah {
    background: #574f48;
}

.grid-view .box--grid .box--column.nasabah .box--column-wrapper,
.grid-view.news .box--column.kontak-media .box--column-wrapper,
.grid-view.news .box--column.news .box--column-wrapper,
.grid-view.news .box--column.laporan .box--column-wrapper {
    padding: 40px 20px;
}

.grid-view .box--grid .box--column.nasabah .text .link a,
.grid-view .box--grid .box--column.nasabah .text .link span {
    display: inline;
    margin: 0;
    padding: 0;
    font-family: "Ubuntu";
    font-size: 14px;
    text-transform: uppercase;
    line-height: 18px;
    text-decoration: none;
    color: #ccc;
}

.grid-view .box--grid .box--column.nasabah .text .link span {
    padding: 0 2px
}

.grid-view .box--grid .box--column.nasabah .text .link a:hover {
    color: #FFF;
}

.grid-view .box--grid .box--column.callus {
    text-align: center;
    height: 100%;
    box-sizing: border-box;
}

.grid-view .box--grid .box--column.callus .text img {
    margin: 0;
}

.grid-view .box--grid .box--column.callus .text h5 {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 10px;
}

.grid-view .box--grid .box--column.callus .text a {
    font-size: 14px;
    color: #ffff;
}

.grid-view .box--grid .box--column.fund.testi h3,
.grid-view .box--grid .box--column.fund.testi p {
    color: #ffff;
}

.grid-view .box--grid .box--column.callus .text:after {
    opacity: 0;
    content: "";
    display: block;
    width: 141px;
    height: 130px;
    position: absolute;
    background: url(../images/material/telephonee.gif) no-repeat center;
    background-size: 108% auto;
    border: none;
    top: 17px;
    left: 0;
    right: 0;
    margin: auto;
}

.grid-view .box--grid .box--column.callus:hover .text:after {
    opacity: 1;
}

.grid-view .box--grid .box--column.callus:hover .text img {
    opacity: 0;
}

.grid-view .box--grid .box--column-wrapper {
    position: relative;
    height: 100%;
    max-height: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.grid-view .box--grid .box--column.fund-linked .box--column-wrapper,
.grid-view .box--grid .box--column-wrapper.testi {
    display: flex;
    flex-flow: column;
    align-self: center;
    justify-content: center;
}

.grid-view .box--grid .twitter .box--column-wrapper {
    display: flex;
    align-self: center;
    justify-content: center;
    height: 100;
    flex-flow: column;
}

.grid-view .box--grid .customer.connect .box--column-wrapper {
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    align-self: center;
    text-align: left;
    flex-flow: column;
}

.grid-view .box--grid .customer.connect .box--column-wrapper .icon {
    margin-bottom: 5px;
}

.grid-view .box--grid .box--column.customer.connect .text a.btn {
    color: #ffff;
}

.grid-view .box--grid .callus .box--column-wrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    height: 100%;
    flex-flow: column;
}

.grid-view .box--grid .callus .box--column-wrapper .text {
    padding: 40px 20px;
}

.grid-view .box--grid .twitter {
    background: #2690CF;
}

.grid-view .box--grid .twitter h5 {
    color: #ffff;
}

.grid-view .box .social-embed,
.grid-view .box--grid .social-embed {
    padding: 20px;
    position: relative;
    z-index: 1;
}

.grid-view .box .social-embed h5,
.grid-view .box--grid .social-embed h5 {
    font-size: 20px;
    margin: 0 0 30px;
}

.grid-view .box .social-embed h5 b,
.grid-view .box--grid .social-embed h5 b {
    text-transform: uppercase;
}

.grid-view .box .social-embed .embed-area,
.grid-view .box--grid .social-embed .embed-area {
    border: 10px solid #176899;
    height: 315px;
    position: relative;
    overflow: hidden;
}

.grid-view .box .social-embed iframe,
.grid-view .box--grid .social-embed iframe {
    max-height: 315px;
    width: 100% !important;
}

.grid-view .box--grid .box--column.facebook-e {
    background: #3042a9
}

.grid-view .box--banner.facebook-e .social-embed .embed-area,
.grid-view .box--grid .social-embed .embed-area {
    border-color: #ffff
}

.wd-auto {
    width: auto !important;
}

.content .banner {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.content .banner img {
    display: block;
    min-height: 100%;
    min-width: 100%;
    max-width: none;
}

.content .content-wrapper {
    background: white;
    margin: 0;
    position: relative;
    padding: 20px 0px 20px 0px;
    z-index: 1;
}

.content .title-page {
    background: #FFF;
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: absolute;
    margin: -70px 0 0;
    padding: 0 13.1% 0 0;
}

.content .title-page h6 {
    font-size: 18px;
    color: #d40d15;
    text-transform: uppercase;
}

.content .title-page .ico {
    width: 90px;
    height: 90px;
    float: left;
    margin: 0 40px 0 0;
    background: #999 url(../images/material/dot-ico.png) no-repeat center;
}

.content .title-page .nav {
    width: 90px;
    position: absolute;
    top: 90px;
}

.content .title-page .nav a {
    width: 90px;
    height: 90px;
    display: block;
    text-indent: -9999px;
    background: #eaeaea url(../images/material/arr-nav-pages.png) no-repeat;
    margin-top: 1px;
}

.content .title-page .nav a.next {
    background-position: right
}

.content .title-page .nav a:hover {
    background-color: #212121
}

.inner-content {
    width: 985px;
    margin: 0px;
    font-family: "Ubuntu";
}

.inner-content h2 {
    font-weight: 500;
    font-size: 70px;
    color: #111;
    margin: 0 0 40px;
    line-height: 75px;
}

.inner-content h4 {
    font-weight: 300;
    font-size: 36px;
    color: #999;
    line-height: 40px;
    text-align: justify;
}

.inner-content h4:after {
    content: "";
    display: block;
    width: 105px;
    margin: 60px 0 30px;
    border-top: 2px solid #e2211a;
}

.inner-content.product-detail .grid-view .box {
    min-height: 300px;
}

.wrap-popup .box-popup.about p,
.wrap-popup .box-popup.about ul li,
.inner-content table,
.inner-content ul li,
.inner-content ol li,
.inner-content p {
    font-size: 18px;
    color: #666;
    line-height: 30px;
    margin-bottom: 40px;
}

.inner-content p {
    margin-bottom: 10px;
}

.wrap-popup .box-popup.about ul li,
.inner-content ul li,
.inner-content ol li {
    margin: 0;
    padding: 0 0 0 20px;
    background: url(../images/material/list-dot.png) no-repeat left 13px;
}

.inner-content blockquote,
.inner-content p.quote {
    font-size: 36px;
    font-style: italic;
    font-weight: 300;
    line-height: 40px;
    margin: 0 0 40px 0;
    border-left: 4px solid #e2211a;
    padding: 0 0 0 30px;
}

.inner-content blockquote b,
.inner-content p.quote b {
    font-weight: 600;
}

.inner-content .image.offset {
    margin: 60px 0 60px -130px;
}

.inner-content #map_contact.image.offset {
    margin: 60px 0 60px -60px;
}

.inner-content .share {
    width: 240px;
    padding: 25px 0 0;
    margin: 100px 0 120px 0;
    border-top: 2px solid #eaeaea;
}

.inner-content .share h6 {
    font-size: 14px;
    color: #999;
    margin: 0 0 12px
}

.inner-content .share a {
    display: block;
    float: left;
    margin: 0 35px 0 0;
    color: #999;
    line-height: 28px
}

.inner-content .share a span {
    display: block;
    width: 26px;
    height: 26px;
    background-position: right center !important;
    float: left;
    margin: 0 5px 0 0
}

.inner-content .share a:hover span {
    background-position: left center !important;
}

.inner-content .share a:nth-of-type(1):hover {
    color: #3042a9
}

.inner-content .share a:nth-of-type(2):hover {
    color: #2790cf
}

.inner-content .list-direksi {
    position: relative;
    margin-top: 60px
}

.inner-content .list-direksi .box-title,
.inner-content .list-direksi .box {
    float: none;
    width: 490px;
    min-height: 600px;
    height: auto;
    margin: 2px 1px 0 0;
    position: relative;
    cursor: pointer;
    z-index: 1;
    display: inline-block;
    vertical-align: top;
}

.inner-content .list-direksi .box.active {
    z-index: 2;
}

.inner-content .list-direksi .box-title {
    background: #0076bd;
    color: #FFF;
}

.inner-content .list-direksi .box-title h2 {
    font-size: 48px;
    font-weight: 300;
    color: #FFF;
    line-height: 54px;
    margin: 115px 60px 0
}

.inner-content .list-direksi .box .image {
    margin: 0;
    height: 600px;
    z-index: 1;
}

.inner-content .list-direksi .box .box-blue {
    position: absolute;
    width: 100%;
    height: 600px;
    top: 0;
    right: 0;
    z-index: 0;
    padding: 0 0 0 0px
}

.inner-content .list-direksi .box .text h3 {
    font-size: 45px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0 0 10px
}

.inner-content .list-direksi .box .text i {
    font-family: "Ubuntu";
    font-size: 18px;
}

.inner-content .list-direksi .box .text {
    position: absolute;
    top: 450px;
    left: 30px;
    z-index: 2;
    color: #FFF;
    width: 100%
}

.inner-content .list-direksi .box .desc {
    width: 200%;
    display: none;
    overflow: hidden;
    height: 0;
    margin: -1px 0 0 -100%;
    border: 1px solid #eaeaea;
}

.inner-content .list-direksi .box .desc p {
    padding: 90px 150px 0;
    margin: 0
}

.inner-content .list-direksi .box .desc a {
    display: inline-block;
    color: #0076bd;
    position: static;
    margin: 0 150px 0;
    padding: 50px 30px 90px 0;
    background: url(../images/material/arr-blue.png) no-repeat right 50px;
    background-position: 99% 50px;
}

.inner-content .list-direksi .box a.collapse:hover {
    color: #e2211a;
}

.inner-content .list-direksi .box .collapse {
    position: absolute;
    font-size: 12px;
    font-family: "Montserrat";
    color: #FFF;
    padding: 0 26px 0 0;
    background: url(../images/material/arr-button.png) no-repeat right center;
    right: 40px;
    top: 540px;
    height: 16px;
    line-height: 16px;
    display: none;
    z-index: 5;
}

.inner-content .list-direksi .box.active .text {
    z-index: 5;
}

.inner-content .list-direksi .box.active .box-blue {
    z-index: 3;
    background: rgb(0, 118, 189)
}

.inner-content .list-direksi .box.active .image {
    z-index: 4;
}

.inner-content .list-direksi .box.active .desc {
    z-index: 2;
    background: #FFF;
    position: relative
}

.inner-content .timeline {
    text-align: center;
}

.inner-content .timeline p {
    margin: 0 0 70px;
}

.inner-content .timeline p,
.inner-content .timeline .image {
    display: none;
}

.inner-content .timeline .box {
    position: relative;
    display: table;
    margin: 0 auto 50px;
    width: 100px;
    height: 100px;
    border: 2px solid #ccc;
}

.inner-content .timeline .box h3 {
    display: table-cell;
    vertical-align: middle;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    position: relative;
    color: #666;
}

.inner-content .timeline .box h3:after {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-bottom: 2px solid #e2211a;
}

.inner-content .timeline .wrap-box {
    position: relative;
    cursor: pointer;
}

.inner-content .timeline .wrap-box.active .box {
    margin-top: -50px;
    background: #e2211a;
    border-color: #e2211a;
    color: #FFF;
}

.inner-content .timeline .wrap-box.active .box h3 {
    color: #FFF;
}

.inner-content .timeline .wrap-box:first-child:before {
    display: none;
}

.inner-content .timeline .wrap-box:before {
    content: "";
    position: absolute;
    border-left: 2px dotted #CCC;
    height: 50px;
    bottom: 100%;
    margin: auto;
    width: 0;
    left: 0;
    right: 0;
}

.inner-content .timeline .wrap-box.active .box:before {
    display: none
}

.inner-content .timeline .wrap-box.active .box h3:after {
    border-color: #FFF;
}

.inner-content .timeline .wrap-box:last-child h3:after {
    display: none;
}

.inner-content .wrap-tab {
    position: relative;
    margin: 0 0 80px;
}

.inner-content .wrap-tab .nav-tab {
    padding: 10px 0 0 20px;
    background: #eaeaea;
    margin: 0 0 60px;
}

.inner-content .wrap-tab .nav-tab a {
    display: block;
    float: left;
    width: 100px;
    font-family: "Ubuntu";
    font-weight: bold;
    color: #666;
    background: #c3c3c3;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 18px;
    margin: 0 3px 0 0;
}

.inner-content .wrap-tab .nav-tab a:hover {
    color: #FFF;
}

.inner-content .wrap-tab .nav-tab a.active {
    color: #333;
    background: #FFF;
}

.inner-content .wrap-tab .content-tab {
    display: none;
}

.inner-content h5 {
    font-weight: 600;
    color: #333;
    margin: 0 0 40px 0;
    text-transform: uppercase;
    font-size: 30px;
}

.list-box {
    position: relative;
}

.list-box .box {
    float: left;
    width: 325px;
    height: 325px;
    border: 1px solid #eaeaea;
    margin: 0 2px 2px 0;
    position: relative;
    font-family: "Montserrat";
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.list-box .box:nth-child(3n) {
    margin-right: 0;
}

.list-box .box .label-ico {
    position: absolute;
    top: 20px;
    right: 20px;
}

.list-box .box .text {
    padding: 95px 40px 0;
    position: relative
}

.list-box .box h6 {
    font-size: 24px;
    color: #666;
    line-height: 26px;
}

.list-box .box p {
    font-family: "Ubuntu";
    font-size: 16px;
    margin: 0;
    color: #999;
}

.list-box .box a {
    position: absolute;
    left: 40px;
    bottom: 40px;
    color: #666;
    display: block;
    height: 20px;
    line-height: 22px;
}

.list-box .box a:hover {
    color: #e2211a;
}

.list-box .box:hover .text * {
    color: #FFF;
}

.list-box--column.laporan .box a {
    padding: 0 30px 0 0;
    background: url(../images/material/arr-download.png) no-repeat right center
}

.list-box.penghargaan .box a {
    padding: 0 20px 0 0;
    background: url(../images/material/arr-plus.png) no-repeat right center;
}

.list-box.penghargaan .box .label-ico {
    background: url(../images/material/label-penghargaan_.png) no-repeat center;
}

.list-box.penghargaan .box .bg-hover {
    background: #0076bd;
}

.list-box.penghargaan .box:hover a {
    background: url(../images/material/arr-plus_.png) no-repeat right center;
}

.list-box .box:hover .label-ico img {
    opacity: 0;
}

.list-box .box:hover a {
    color: #FFF;
}

.list-box .box .bg-hover {
    background: #666;
}

.list-box--column.laporan .box .bg-hover {
    background: #e2211a;
}

.list-box--column.laporan .box .label-ico {
    background: url(../images/material/label-lap-red.png) no-repeat center;
}

.list-box--column.laporan .box:hover a {
    background: url(../images/material/arr-download_.png) no-repeat right center;
}

.list-box.konvensional .box .bg-hover {
    background: #0378be;
}

.list-box.konvensional .box .label-ico {
    background: url(../images/material/label-lap-blue.png) no-repeat center;
}

.list-box.konvensional .box:hover a {
    background: url(../images/material/arr-download_.png) no-repeat right center;
}

.list-box.syariah .box .bg-hover {
    background: #13915a;
}

.list-box.syariah .box .label-ico {
    background: url(../images/material/label-lap-syariah.png) no-repeat center;
}

.list-box.syariah .box:hover a {
    background: url(../images/material/arr-download_.png) no-repeat right center;
}

.list-box.kantor .box .bg-hover {
    background: #e2211a;
}

.list-box.kantor .box .label-ico {
    background: url(../images/material/label-map_.png) no-repeat center;
}

.list-box.kantor .box:hover a {
    background: url(../images/material/searchwhite-ico.png) no-repeat right center;
}

.list-box.career_list .box .bg-hover {
    background: #e2211a;
}

.list-box.career_list .box .label-ico {
    background: url(../images/material/label-career_.png) no-repeat center;
}

.list-box.career_list .box:hover a.apply {
    background: url(../images/material/ico_apply_.png) no-repeat right center;
}

.list-rekanan .box {
    padding: 20px 0;
    border-bottom: 1px solid #eaeaea
}

.list-rekanan .box h3 {
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    color: #333;
    /*height: 45px;*/
    line-height: 45px;
    border-left: 5px solid #e2211a;
    padding: 0 0 0 30px;
    cursor: pointer;
}

.list-rekanan .box .desc {
    padding: 40px 0 40px 35px;
    display: none;
}

.list-rekanan .box .desc p {
    margin: 0 0 10px
}

.list-rekanan .box.active .desc {
    display: block;
}

.list-rekanan .box.active h3 {
    color: #e2211a;
}

.bar-filter {
    background: #999;
    height: 90px;
}

.bar-filter .search,
.bar-filter .dropdown {
    border-left: 1px solid #FFF;
    width: auto
}

.bar-filter .search input {
    padding: 0 0 0 55px;
    font-size: 16px;
    color: #FFF;
    border: none;
    height: 90px;
    background: url(../images/material/search-white-ico.png) no-repeat 20px center;
}

.bar-filter .search input[type=text]::-webkit-input-placeholder {
    color: #FFF;
    font-style: italic;
}

.bar-filter .search input[type=text]:-moz-placeholder {
    color: #FFF;
    font-style: italic;
}

.bar-filter .search input[type=text]::-moz-placeholder {
    color: #FFF;
    font-style: italic;
}

.bar-filter .search input[type=text]:-ms-input-placeholder {
    color: #FFF;
    font-style: italic;
}

.bar-filter .dropdown:first-child {
    border: none;
}

.bar-filter .dropdown select {
    height: 90px;
    padding: 0;
    border: none;
    width: 280px;
    font-size: 16px;
    text-transform: capitalize
}

.bar-filter .dropdown.right select {
    width: 215px;
}

.bar-filter .dropdown .cover-combo {
    position: relative;
    height: 90px;
    padding: 0;
    background: url(../images/material/dropdown-prod-ico.png) no-repeat 28px center;
}

.bar-filter .dropdown.right .cover-combo {
    background: url(../images/material/sort-ico.png) no-repeat 28px center;
}

.bar-filter .dropdown .cover-combo:after {
    content: "";
    position: absolute;
    display: block;
    background: url(../images/material/arr-white.png);
    width: 14px;
    height: 8px;
    margin: auto;
    right: 25px;
    top: 0;
    bottom: 0;
}

.bar-filter .dropdown .inner-combo {
    font-size: 16px;
    font-family: "Ubuntu";
    font-weight: bold;
    color: #FFF;
    line-height: 90px;
    overflow: hidden;
    margin: 0 45px 0 60px;
    text-transform: uppercase;
}

.inner-content h6 {
    font-size: 22px;
    color: #0076bd;
    font-weight: bold;
    margin: 0 0 5px
}

.inner-content.product-detail table {
    margin: 0 0 80px;
    width: 100%;
}

.inner-content.product-detail table tr {
    border-bottom: 1px solid #eaeaea
}

.inner-content.product-detail table tr:first-child {
    border-top: 1px solid #eaeaea;
}

.inner-content.product-detail table tr th {
    font-weight: bold;
    width: 320px;
    text-align: left;
    padding: 15px 0
}

.inner-content.product-detail table tr td {
    padding: 15px 0;
}

.inner-content ul.col-3 li {
    width: 280px;
    float: left;
    margin-right: 40px;
}

.inner-content ul.col-3 li:nth-child(3n) {
    margin-right: 0;
}

.inner-content ul.col-3 li a {
    color: #0076bd;
    font-size: 14px;
}

.inner-content ul.col-3 li a:hover {
    color: #e2211a;
}

.inner-content ul.col-3 {
    margin-bottom: 60px;
}

.inner-content .grid-view {
    margin-bottom: 40px;
    margin-top: 70px;
}

.inner-content .grid-view .box {
    width: 33%;
}

.inner-content .grid-view .box.brosur {
    background: #e2211a;
}

.inner-content .grid-view .box.brosur p {
    color: #fff;
    margin: 0 0 10px;
}

.inner-content .grid-view .box.brosur h3 {
    font-size: 38px;
    font-family: "Montserrat";
    margin-bottom: 40px;
}

.inner-content .grid-view .box.brosur a {
    background: url(../images/material/ico-download.png) no-repeat right center;
    padding: 0 28px 0 0;
}

.inner-content .grid-view .box--grid .box--column.callus h5 {
    color: #FFF;
    font-size: 20px;
    font-family: "Montserrat";
    margin-bottom: 5px;
}

.inner-content .grid-view .box--grid .box--column.callus h3 {
    color: #FFF;
    font-size: 18px;
    font-family: "Montserrat";
}

.inner-content .grid-view .box--grid .box--column.callus a {
    width: 88px;
    left: 0;
    right: 0;
    margin: auto
}

.inner-content .grid-view .box--grid .box--column.callus img {
    width: 90px;
    margin-bottom: 10px
}

.inner-content .grid-view .box--grid .box--column.callus .text:after {
    display: none;
}

.inner-content .grid-view .box--grid .box--column.callus:hover .text img {
    opacity: 0;
}

.inner-content .grid-view .box--grid .box--column.callus .text:after {
    opacity: 0;
    content: "";
    display: block;
    width: 91px;
    height: 78px;
    position: absolute;
    background: url(../images/material/telephonee.gif) no-repeat center;
    background-size: 108% auto;
    border: none;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
}

.inner-content .grid-view .box--grid .box--column.callus:hover .text:after {
    opacity: 1;
}

.inner-content .image i {
    font-size: 14px;
    color: #999;
    padding: 10px 0 0 0;
    color: #999;
    line-height: 20px;
    display: block;
}

.inner-content h4 .date {
    color: #666;
    display: block;
    margin-top: 25px;
    padding: 0 0 0 26px;
    font-size: 16px;
    font-weight: 400;
    background: url(../images/material/date-red-ico.png) no-repeat left center;
}

.inner-content .box-testi {
    margin: 0 0 115px;
}

.inner-content .box-testi .image {
    width: 100px;
}

.inner-content .box-testi .text {
    width: 860px;
}

.inner-content .box-testi .text h5 {
    font-size: 36px;
    color: #333;
    font-family: "Montserrat";
    margin: 18px 0 5px 0;
    text-transform: capitalize;
    font-weight: normal
}

.inner-content .box-testi .text h6 {
    font-style: italic;
    font-weight: normal;
    color: #999;
    font-size: 18px;
    margin: 0 0 50px 0;
}

.inner-content .box-testi .text p.quote {
    background: url(../images/material/quote.png) no-repeat left 5px;
    border: none;
    font-size: 24px;
    color: #333;
    font-weight: normal;
    line-height: 30px;
    margin: 0 0 20px -30px;
}

.inner-content .box-testi .text p.quote::after {
    content: '';
    position: relative;
    margin-left: 10px;
    top: 5px;
    width: 30px;
    height: 30px;
    display: inline-block;
    background: url('../images/material/quote2.png') no-repeat;
}

.layanan .wrap-tab .nav-tab a {
    width: 205px;
}

.layanan .list-box--column.laporan b {
    font-size: 14px;
    color: #999
}

.filter-tahun {
    margin: 60px 0 25px 0;
}

.filter-tahun select {
    width: 140px;
    height: 40px;
    padding: 0;
}

.filter-tahun label {
    color: #666;
    font-size: 18px;
    line-height: 40px;
    margin: 0 30px 0 0;
}

.filter-tahun .cover-combo {
    background: url(../images/material/arr-combored.png) no-repeat 110px center;
    border: 1px solid #d8d8d8;
}

.filter-tahun .inner-combo {
    margin: 0 37px 0 15px;
    line-height: 40px;
    font-size: 16px;
    color: #999;
}

.filter-tahun input[type=text] {
    width: 190px;
    height: 38px;
    border-color: #d8d8d8;
    padding: 0 30px 0 20px;
    font-size: 16px;
    margin: 0 20px 0 0;
    background: url(../images/material/birthday-ico.png) no-repeat 210px center;
}

.filter-tahun a.print {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 30px 0 0;
    background: url(../images/material/print-ico.png)no-repeat right center;
    font-size: 16px;
    color: #666;
}

.filter-tahun a.print:hover {
    color: #e2211a;
    background: url(../images/material/printred-ico.png) no-repeat right center;
}

.kantor_map {
    position: relative;
}

.kantor_map #map_kantor {
    background: #DDD;
    height: 590px;
}

.kantor_map select {
    height: 80px;
    width: 315px;
    padding: 0;
    font-size: 16px;
}

.kantor_map .cover-combo {
    background: #0076bd url(../images/material/arr-white.png) no-repeat 270px center;
    color: #FFF;
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    top: auto !important;
    left: auto !important;
    height: 80px;
}

.kantor_map .inner-combo {
    line-height: 80px;
    font-size: 18px;
    margin: 0 45px 0 20px;
}

.list-box .box h6 {
    color: #333;
    text-transform: uppercase;
}

.list-box .box p {
    font-size: 16px;
    line-height: 20px;
}

.list-box .box p span {
    color: #0076bd;
    font-weight: bold;
    -webkit-appearance: none;
}

.list-box .box p b {
    display: block;
    margin: 10px 0 0 0;
    -webkit-appearance: none;
}

.list-box .box p a {
    position: static;
    background: none;
    color: #999;
    display: inline;
}

.list-box .box a {
    background: url(../images/material/searchred-ico.png) no-repeat right center;
    padding: 0 20px 0 0;
}

.price {
    vertical-align: middle;
    width: 100%;
}

.price thead {
    background: #3b3b3b;
    color: #FFF;
    text-transform: uppercase;
}

.price td,
.price thead th {
    vertical-align: middle;
    font-weight: bold;
    padding: 20px 25px;
    border-right: 1px solid #292929;
}

.price td:last-child,
.price thead th:last-child {
    border-right: none;
}

.price tbody td {
    border-right: 1px solid #ccc;
    background: #f5f5f5;
    border-bottom: 1px solid #FFF;
}

.price thead th:nth-child(1) {
    width: 570px;
}

.price thead th:nth-child(2) {
    width: 110px;
}

.price tbody td:nth-child(1) {
    text-align: left;
}

.price tbody td:nth-child(2) {
    text-align: center;
}

.price tbody td:nth-child(3) {
    text-align: right;
}

.price tbody tr:nth-child(2n) td {
    background: #eaeaea;
}

.inner-content .grid-view.career .box {
    background: #CCC;
    text-align: center;
    font-family: "Montserrat";
}

.inner-content .grid-view.career .text img {
    display: block;
    margin: 0 auto 20px;
}

.inner-content .grid-view.career .text h3 {
    font-size: 24px;
    color: #FFF;
    font-size: 24px;
    color: #FFF;
    text-transform: uppercase;
}

.inner-content .grid-view.career .text p {
    font-size: 14px;
    color: #FFF;
    margin: 10px 0 50px 0;
}

.inner-content .grid-view.career .text a {
    margin-right: 0;
}

.inner-content .grid-view.career .lowongan {
    background: #e2211a;
}

.inner-content .grid-view.career .lowongan .text a,
.inner-content .grid-view.career .apply .text a {
    color: #ffff;
}

.inner-content .grid-view.career .apply {
    background: #0076bd;
}

.inner-content .grid-view.career .bg-hover {
    background: #212121;
}

.grid-view.news .box.slider-wrap h2 {
    font-size: 48px;
    text-transform: none;
    margin: 0 0 40px 0;
    line-height: 50px;
}

.grid-view.news .box.slider-wrap span.date {
    font-size: 18px;
}

.grid-view.news .box.slider-wrap .text {
    bottom: 30%;
}

.grid-view.news .box--column.kontak-media {
    background: #424242;
    color: #FFF;
}

.grid-view .box--column.news .box--column-wrapper,
.grid-view.news .box--column.laporan .box--column-wrapper,
.grid-view.news .box--column.kontak-media .box--column-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-self: stretch;
    height: 100%;
}

.grid-view.news .box--column.kontak-media h3 {
    font-size: 50px;
    text-transform: uppercase;
    line-height: 48px;
    margin: 0 0 15px;
}

.grid-view.news .box--column.kontak-media p {
    font-size: 16px;
    line-height: 18px;
}

.grid-view.news .box--column.kontak-media h6 {
    font-size: 24px;
    margin: 40px 0 5px;
}

.grid-view.news .box--column.kontak-media i {
    font-size: 14px;
    display: block;
    margin: 0 0 10px;
}

.grid-view.news .box--column.kontak-media a.mail {
    color: #ffa726;
    background: none;
    position: static;
    padding: 0 0 50px 0;
    text-decoration: underline;
}

.grid-view.news .box--column.kontak-media a {
    margin: 0;
    right: 30px;
    bottom: 30px;
    position: absolute;
}

.grid-view.news .box--column.laporan {
    background: #00395c;
}

.grid-view .box--column.laporan h3 {
    font-size: 38px;
    text-transform: uppercase;
    margin: 0 0 150px 0;
}

.grid-view.news .box--column.laporan p {
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 0 5px;
}

.grid-view .box--grid .testi {
    color: #ffff;
}

.grid-view .box--grid .testi .bx-viewport {
    background: none;
}

.grid-view .box--grid .testi h3 {
    margin: 35px 0 30px 0;
}

.grid-view .box--grid .testi .text_ {
    padding: 0 30px;
}

.grid-view .box--grid .testi .img-testi {
    width: 59px;
    height: 59px;
    border: 3px solid #fff;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    margin: 0 auto 5px;
    overflow: hidden;
    position: relative;
    display: none;
}

.grid-view .box--grid .testi h6 {
    font-size: 12px;
    color: #fff;
    margin: 0 0 20px;
}

.grid-view .box--grid .testi p {
    font-size: 18px;
    font-style: italic;
    margin: 0 0 0px;
    overflow: hidden;
    height: 61px;
}

.grid-view .box--grid .testi a {
    font-size: 12px;
}

.grid-view .box--grid .testi .bx-wrapper .bx-controls-direction {
    bottom: 25%
}

.grid-view .box--grid .box--column.fund .bx-wrapper .bx-pager {
    display: none;
}

.grid-view .box--grid .box--column.fund .pager-info {
    color: #ffff;
    font-size: 14px;
}

.grid-view .box--grid .pager-info {
    position: absolute;
    margin: auto;
    left: 50%;
    text-align: center;
}

.box-rekomendasi {
    padding: 50px 0 90px;
}

.box-rekomendasi .row {
    margin-bottom: 90px;
}

.box-rekomendasi .row:after {
    content: '';
    display: block;
    clear: both;
}

.box-rekomendasi .row .col {
    float: left;
    width: 48%;
    margin-right: 4%;
}

.box-rekomendasi .row .col:last-child {
    margin-right: 0;
}

.box-rekomendasi h3 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
}

.btn-readmore {
    margin-top: 10px;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: #0077bd;
    line-height: 50px;
    display: inline-block;
    padding: 0 40px;
    box-sizing: border-box;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.btn-readmore:hover {
    background: #009ffc;
    color: #fff;
}

.box-slideristimewa {
    margin-top: 60px;
}

.box-slideristimewa:after {
    content: '';
    display: block;
    clear: both;
}

.box-slideristimewa .list-slider:hover p {
    color: #000;
}

.box-slideristimewa .list-slider {
    float: left;
    text-align: center;
    position: relative;
    padding: 0 5px;
    box-sizing: border-box;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.box-slideristimewa .list-slider .col {
    padding: 40px 30px 75px;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
}

.box-slideristimewa .list-slider:last-child {}

.box-slideristimewa .list-slider:after {
    content: '';
    position: absolute;
    width: 1px;
    height: auto;
    background: #eaeaea;
    right: 2px;
    top: 0;
    bottom: 0;
    display: none;
}

.box-slideristimewa.noborder .list-slider:last-child:after {
    content: '';
    position: absolute;
    width: 1px;
    height: auto;
    background: #eaeaea;
    right: 0px;
    top: 0;
    bottom: 0;
    display: none;
}

.box-slideristimewa:before {
    content: '';
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: auto;
    width: 1px;
    background: #eaeaea;
}

.box-slideristimewa.noborder:before {
    display: none;
}

.box-slideristimewa .list-slider p {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    height: auto;
    overflow: hidden;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.box-slideristimewa .list-slider .ico {
    margin-bottom: 25px;
    display: block;
}

.box-slideristimewa .list-slider .ico img {
    margin: 0 auto 20px;
    text-align: center;
}

.tittle-slider:after {
    display: block;
    clear: both;
    content: '';
}

.tittle-slider {
    position: relative;
    margin: 30px 0;
}

.tittle-slider .btn-readmore {
    float: right;
    margin-top: 0;
}

.tittle-slider h3 {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    float: left;
    line-height: 50px;
}

.box-slideristimewa .slick-arrow {
    position: absolute;
    right: 0;
    top: -40px;
}

.box-slideristimewa .slick-arrow.slick-prev {
    text-indent: -999999px;
    border: none;
    background: url('../images/material/arr-slider.png')no-repeat left;
    padding: 0;
    cursor: pointer;
    width: 9px;
    height: 17px;
    position: absolute;
    left: auto;
    right: 60px;
}

.box-slideristimewa .slick-arrow.slick-next {
    text-indent: -999999px;
    border: none;
    background: url('../images/material/arr-slider.png')no-repeat right;
    padding: 0;
    cursor: pointer;
    width: 9px;
    height: 17px;
    position: absolute;
    left: auto;
    right: 40px;
}

.box-slideristimewa .slick-arrow.slick-disabled {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.box-slidermanfaat {
    margin-bottom: 60px;
}

.box-slidermanfaat:after {
    content: '';
    display: block;
    clear: both;
}

.box-slidermanfaat .list-slider {
    float: left;
    padding: 0 5px;
    box-sizing: border-box;
}

.box-slidermanfaat .list-slider .col {
    display: block;
    padding: 40px 30px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    border: 1px solid #eaeaea;
}

.box-slidermanfaat .list-slider .col:after {
    content: '';
    position: absolute;
    right: 2px;
    top: 0;
    bottom: 0;
    width: 1px;
    height: auto;
    background: #eaeaea;
    display: none;
}

.box-slidermanfaat .list-slider p {
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    height: auto;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.box-slidermanfaat .list-slider .ico img {
    margin: 0 auto 20px;
    text-align: center;
}

.box-slidermanfaat .slick-arrow {
    position: absolute;
    right: 0;
    top: -60px;
}

.box-slidermanfaat .slick-arrow.slick-prev {
    text-indent: -999999px;
    border: none;
    background: url('../images/material/arr-slider-red.png')no-repeat left;
    padding: 0;
    cursor: pointer;
    width: 10px;
    height: 17px;
    position: absolute;
    left: auto;
    right: 60px;
}

.box-slidermanfaat .slick-arrow.slick-next {
    text-indent: -999999px;
    border: none;
    background: url('../images/material/arr-slider-red.png')no-repeat right;
    padding: 0;
    cursor: pointer;
    width: 10px;
    height: 17px;
    position: absolute;
    left: auto;
    right: 40px;
}

.box-slidermanfaat .slick-arrow.slick-disabled {
    filter: grayscale(80%);
    -webkit-filter: grayscale(80%);
}

.box-slidermanfaat.noborder .list-slider:first-child:before {
    content: '';
    background: #eaeaea;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.box-slidermanfaat .list-slider:first-child:before {
    content: '';
    background: #eaeaea;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.box-slidermanfaat .list-slider:hover p {
    color: #000;
}

.form-message {
    padding: 50px 0 130px 0;
    box-sizing: border-box;
}

.form-message h3 {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    margin-bottom: 40px;
}

.form-message .row:after {
    content: '';
    display: block;
    clear: both;
}

.form-message .row {
    margin-bottom: 20px;
}

.form-message .col {
    width: 48%;
    margin-right: 4%;
    float: left;
}

.form-message .col.full {
    width: 100%;
}

.form-message .col:last-child {
    margin-right: 0;
}

.form-message label {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #666;
    margin-bottom: 15px;
}

.form-message input[type='text'],
input[type='file'],
select {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    font-size: 16px;
    color: #bdbdbd;
}

.form-message textarea {
    width: 100%;
    font-size: 16px;
    box-sizing: border-box;
    height: 140px;
    color: #bdbdbd;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
}

.form-message .input_file {
    width: 100% !important;
    box-sizing: border-box;
    height: 60px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    background: url('../images/material/input-file.png')no-repeat 97% center;
    cursor: pointer;
}

.form-message select {
    background: #fff url('../images/material/arr-selectfloat.png')no-repeat right;
    z-index: 1
}

.btn-readmore-red {
    width: 220px;
    line-height: 50px;
    font-size: 18px;
    background: #e2211a;
    color: #fff;
    display: inline-block;
    border: none;
    border-radius: 2px;
    margin-top: 35px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.btn-readmore-red:hover {
    background: #dd3e38;
}

.ui-timepicker-div .ui-widget-header {
    margin-bottom: 8px;
}

.ui-timepicker-div dl {
    text-align: left;
}

.ui-timepicker-div dl dt {
    height: 25px;
    margin-bottom: -25px;
}

.ui-timepicker-div dl dd {
    margin: 0 10px 10px 65px;
}

.ui-timepicker-div td {
    font-size: 90%;
}

.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.ui-timepicker-rtl {
    direction: rtl;
}

.ui-timepicker-rtl dl {
    text-align: right;
}

.ui-timepicker-rtl dl dd {
    margin: 0 65px 10px 10px;
}

.ui_tpicker_unit_hide {
    display: none !important;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
}


/* popup
----------------------------------------------------------------------------------------------*/

.wrap-popup {
    position: fixed;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.8);
    display: none;
}

.wrap-popup .box-popup {
    position: relative;
    margin: 5% auto;
    font-family: "Ubuntu";
}

.wrap-popup .box-popup .close {
    position: absolute;
    width: 55px;
    height: 55px;
    text-indent: -9999px;
    top: 0;
    right: 0;
    /*background: #90201c;*/
    z-index: 2;
}

.wrap-popup .box-popup .close:after {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: block;
    content: "";
    background: url(../images/material/close-ico.png) no-repeat center;
}

.wrap-popup .box-popup .close:after,
.wrap-popup .box-popup .close:before {
    transition: 0.2s all ease-out;
    -webkit-transition: 0.2s all ease-out;
    -moz-transition: 0.2s all ease-out;
    -ms-transition: 0.2s all ease-out;
}

.wrap-popup .box-popup .close:before {
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    display: block;
    background: #212121
}

.wrap-popup .box-popup .close:hover:before {
    width: 100%;
}

.wrap-popup .box-popup .close:hover:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
}

.wrap-popup .box-popup.about {
    background: #FFF;
    padding: 85px;
    width: 430px;
}

.wrap-popup .box-popup.about:before {
    position: absolute;
    content: "";
    display: block;
    height: 255px;
    width: 100%;
    top: 0;
    left: 0;
    /*background:#e2211a*/
    background: #0077be;
}

.wrap-popup .box-popup.about .image {
    margin: 0 0 40px 0;
    min-height: 170px;
}

.wrap-popup .box-popup.about h6 {
    font-size: 20px;
    font-weight: 600;
    color: #999;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}

.wrap-popup .box-popup.about h2 {
    font-size: 50px;
    color: #111;
    line-height: 52px;
    margin: 0 0 30px;
}

.wrap-popup .box-popup.about p {
    margin-bottom: 20px;
}

.wrap-popup .box-popup.about .desc {
    position: relative
}

.box-popup.product {
    width: 600px;
    background: #FFF;
}

.box-popup.product .desc {
    padding: 45px 85px 85px;
}

.box-popup.product .desc h6 {
    font-size: 20px;
    font-weight: bold;
    color: #999;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.box-popup.product .desc h2 {
    font-size: 50px;
    color: #111;
    margin-bottom: 30px;
}

.box-popup.product .desc p {
    font-size: 16px;
    line-height: 24px;
}

.box-popup.product .desc a {
    color: #0478be;
    font-size: 12px;
    color: #0478be;
    margin-top: 35px;
    padding: 0 30px 0 0;
    height: 16px;
    display: inline-block;
    background: url(../images/material/arr-blue.png) no-repeat right;
}

.list-box.career_list .box .kode_career {
    font-weight: 700;
    font-size: 14px;
    color: #999999;
    margin-bottom: 5px;
    display: block;
}

.list-box.career_list .box:hover .kode_career {
    color: #fff;
}

.list-box.career_list .box h6 {
    font-family: "Montserrat";
    line-height: 26px;
    color: #666;
    font-weight: 300;
}

.list-box.career_list .box a.apply {
    background: url('../images/material/ico_apply.png') no-repeat right center;
    padding-right: 30px;
}

.inner-content .pagging {
    margin: 44px 0 130px;
}

.inner-content .pagging a {
    width: 60px;
    height: 60px;
    float: left;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    color: #666666;
    background: #ccc;
    margin-right: 1px;
}

.inner-content .pagging a.active,
.inner-content .pagging a:hover {
    background: #666;
    color: #fff;
}

.inner-content.career .top_src .row {
    padding-bottom: 18px;
    margin-bottom: 40px;
    border-bottom: 1px solid #eaeaea;
}

.inner-content.career .top_src .col {
    float: left;
    margin-right: 10px;
}

.inner-content.career .top_src .col:first-child {
    margin-right: 30px;
}

.inner-content.career .top_src label {
    font-size: 18px;
    color: #666666;
    margin-right: 10px;
    display: inline-block;
}

.inner-content.career .top_src select {
    width: 240px;
    padding: 0;
    height: 40px;
    font-size: 16px;
}

.filter-tahun input[type=submit],
.inner-content.career .top_src input[type="submit"] {
    color: #fff;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    background: #e2211a;
    border: none;
    text-transform: uppercase;
    width: 180px;
    cursor: pointer;
}

.inner-content.career .top_src .cover-combo {
    border: 1px solid #d8d8d8;
    display: inline-block;
    margin-right: 20px;
    background: url('../images/material/arr_select.png') no-repeat 90% center;
    height: 40px;
}

.inner-content.career .top_src .cover-combo .inner-combo {
    font-size: 16px;
    padding: 12px 10px;
    padding-right: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inner-content.career.apply h4 {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    margin-top: -30px;
}

.form_apply {
    margin-bottom: 130px;
}

.form_apply .row {
    margin-bottom: 20px;
}

.form_apply .row .col {
    float: left;
}

.form_apply .row .col.right {
    float: right;
}

.form_apply .row .col label {
    display: block;
    font-size: 18px;
    color: #666;
    margin-bottom: 10px;
}

.form_apply .row .col input[type="text"] {
    width: 435px;
    padding: 20px;
    font-size: 16px;
}

.form_apply .row .col.full input[type="text"] {
    width: 945px;
}

.input_file {
    border: 1px solid #d8d8d8;
    padding: 20px;
    position: relative;
    cursor: pointer;
    background: url('../images/material/file.png') no-repeat 97% center;
    font-size: 16px;
    color: #666;
    width: 435px;
}

.input_file.foto {
    background: url('../images/material/pas_foto.png') no-repeat 97% center;
}

.input_file input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    min-width: 100%;
    padding: 15px 0;
}

.inner-content.contact {
    padding-bottom: 130px;
}

.inner-content .address_our {
    margin-bottom: 70px;
}

.inner-content .address_our .list_address {
    width: 50%;
    float: left;
}

.inner-content .address_our .list_address h6 {
    color: #e2211a;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.inner-content .address_our .list_address p {
    line-height: 24px;
    margin: 0 0 10px 0;
}

.inner-content .address_our .list_address p a {
    color: #666;
}

.inner-content .address_our .list_address p.ic-telp {
    background: url('../images/material/phone-ico.png') no-repeat 0 center;
    padding: 0 0 0 35px;
}

.inner-content .address_our .list_address p.ic-fax {
    background: url('../images/material/fax-ico.png') no-repeat 0 center;
    padding: 0 0 0 35px;
}

#map_contact {
    height: 500px;
}

.inner-content .form_contact form {}

.inner-content .form_contact .row {
    margin: 0 0 25px;
}

.inner-content .form_contact .row label {
    display: block;
    font-size: 20px;
    color: #666666;
    margin-bottom: 14px;
}

.inner-content .form_contact .row label.ket {
    font-size: 14px;
}

.inner-content .form_contact .row input[type="text"],
.inner-content .form_contact .row textarea {
    font-size: 16px;
    padding: 20px;
    color: #999;
    display: inline-block;
    vertical-align: middle;
}

.inner-content .form_contact .row select {
    padding: 0;
    height: 60px;
    font-size: 16px;
    color: #999;
}

.inner-content .form_contact .row:first-child input[type="text"] {
    width: 780px;
}

.inner-content .form_contact .row:first-child select {
    width: 140px;
}

.inner-content .form_contact .row:nth-child(2) input[type="text"] {
    margin-right: 20px;
}

.inner-content .form_contact .row:nth-child(3) input[type="text"] {
    width: 460px;
}

.inner-content .form_contact .row:nth-child(4) select {
    width: 980px;
}

.inner-content .form_contact .row:nth-child(4) .cover-combo {
    background-position: 98% center;
}

.inner-content .form_contact .row input[type="text"] {
    width: 328px;
}

.inner-content .form_contact .row .cover-combo {
    height: 58px;
    border: 1px solid #d8d8d8;
    display: inline-block;
    margin-right: 20px;
    background: url('../images/material/arr_select.png') no-repeat 90% center;
}

.inner-content .form_contact .row .cover-combo .inner-combo {
    font-size: 16px;
    color: #999;
    padding: 20px;
}

.inner-content .form_contact .row .div_check {
    margin-bottom: 15px;
}

.inner-content .form_contact .row .div_check span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;
    font-size: 16px;
    color: #999;
}

.check-custom {
    display: inline-block;
    background: url(../images/material/check_box.png) no-repeat left center;
    width: 21px;
    height: 21px;
    margin: 0 10px 0 0;
    vertical-align: middle;
}

.check-custom.active {
    background-position: right center;
}

.inner-content .form_contact .row textarea {
    width: 938px;
    height: 130px;
}

.inner-content .form_contact .row.check_last .div_check span {
    margin: 0 0 15px;
    display: block;
}

.inner-content .form_contact .row input[type="submit"],
.form_apply .row .col input[type="submit"] {
    background: #e2211a;
    border: none;
    height: 60px;
    color: #fff;
    width: 220px;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.inner-content.result form {
    width: 280px;
    border: 1px solid #D8D8D8;
    margin: 0 0 10px;
}

.inner-content.result form input[type="text"] {
    border: none;
    font-size: 14px;
    width: 225px;
}

.inner-content.result form input[type="submit"] {
    border: none;
    padding: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.inner-content.result p {
    font-size: 14px;
    line-height: 22px;
}

#load {
    display: block;
    margin: 25px auto;
    padding: 30px 0 0;
    background: url('../images/material/icon_see_more.png') no-repeat top center;
    border: none;
    color: #666;
    font-family: "Ubuntu";
    font-size: 12px;
    cursor: pointer;
}

#load:hover {
    color: #0077be;
    background: url('../images/material/icon_see_more_hov.png') no-repeat top center;
}

.ls-acc-wp .btn-acc {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin: 15px 0 15px 50px;
}

.form-message .div_check span {
    font-size: 18px;
    color: #666;
    line-height: 24px;
}


/*
----------------------------------------------------------------------------------------------*/

.text-right {
    text-align: right;
}


/* flexbox
----------------------------------------------------------------------------------------------*/

.flex-col-rev {
    display: flex;
    flex-flow: column-reverse;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex .flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex .flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-space {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


/* grid
----------------------------------------------------------------------------------------------*/

.grid {
    display: grid;
    width: 100%;
}

.grid .grid-item {
    display: inline-grid;
    justify-content: left;
    text-align: left;
    align-content: start;
}

.grid .grid-bottom {
    display: flex;
    align-self: baseline;
    grid-column: 1/5;
}


/* footer
----------------------------------------------------------------------------------------------*/

footer {
    position: relative;
    z-index: 0;
    padding: 40px 60px 120px 60px;
    background-color: #0076BD;
}

footer .grid .grid-nav a {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    box-sizing: content-box;
}

footer h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 13px;
    text-transform: uppercase;
}

footer .border-top {
    border-top: 0.5px solid white;
    padding-top: 20px;
}

footer h5,
footer p,
footer a {
    color: #ffff;
}

footer .social a {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #ffff;
    text-align: center;
    display: inline-block;
}

footer .social a:last-child {
    margin-right: 0px !important;
}

footer .social a .social-wrapper {
    display: flex;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}


/* general
----------------------------------------------------------------------------------------------*/

.align-self--baseline {
    align-self: baseline !important;
    min-height: none !important;
}

.m-auto {
    margin: auto;
}

.p-40 {
    padding: 40px;
}

.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.line-spacer {
    color: #ffff;
    margin-left: 5px;
    margin-right: 5px;
}

.fill-blue {
    fill: #0076BD;
}

.fill-white {
    fill: #ffff;
}

.fill-none {
    fill: none;
}

.fill-grey {
    fill: #555555;
}

.mb-15 {
    margin-bottom: 15px;
}

.text-bold {
    font-weight: 600 !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-justify {
    text-align: justify;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.color-white {
    color: #ffff !important;
}

.color-blue {
    color: #0076bd !important;
}

.color-red {
    color: #E22119 !important;
}

.color-grey {
    color: #555555 !important;
}

.bg-white {
    background-color: #ffff !important;
}

.bg-grey {
    background-color: #555555 !important;
}

.bg-red {
    background-color: #E22119 !important;
}

.bg-blue {
    background-color: #0076bd;
}